export default {
  // ? =========================
  // ? =======  Layout  ========
  // ? =========================

  navigation: {
    '.container': {
      backgroundColor: 'white',
      position: 'static',
      borderBottom: '1px solid lightgrey',
      padding: ['0.5rem', '0.5rem', '1rem', '1rem'],
      zIndex: '1003'
    },
    '.containerScrolled': {
      backgroundColor: 'white',
      borderBottom: '1px solid lightgrey',
      zIndex: '1003'
    },
    '.smallNavMenu > div': {
      borderColor: 'black'
    },
    '.navItem': {
      color: 'black'
    },
    '.hamburger > div': {
      backgroundColor: 'black'
    },
    '.navMenuOpen': {
      backgroundColor: 'background',
      width: '50%'
    },
    '.socialIconsContainer, .phoneContainer': {
      color: 'black',
      borderColor: 'black',
      svg: {
        path: {
          fill: 'black'
        }
      }
    },
    '.logoScrolled': {
      img: {
        maxHeight: ['65px', '65px']
      }
    },

    '.navBlockOpen': {
      width: '50%',
      height: '100vh',
      backgroundColor: '#ffffffc9',
      top: '0rem',
      position: 'fixed',
      left: '50%'
    }
  },
  footer: {
    padding: ['1rem 0.5rem 6rem', '1rem 2rem 6rem ', '1rem 2rem', '1rem 2rem'],
    backgroundColor: 'black',
    display: 'flex',
    flexWrap: 'wrap',
    color: 'white',
    flexDirection: 'row',
    alignItems: 'center',
    '.poweredBy .gonationLogo': {
      filter: 'brightness(0) invert(1)'
    },
    '.copyright': {
      backgroundColor: 'transparent',
      color: 'white',
      margin: '1rem 1rem'
    },
    '.multiButtonContainer, .image, .socialContainer  ': {
      display: 'none'
    },

    '.contactDetails-container': {
      display: 'flex',
      color: 'white',
      flexDirection: 'row',
      alignItems: 'center',
      '.text': {
        margin: '0rem 1rem',
        fontSize: '0.8rem',
        display: 'flex',
        color: 'white',
        flexDirection: 'row',
        alignItems: 'center'
      }
    }
  },

  ctaWidget: {
    a: {
      backgroundColor: 'secondary',
      borderRadius: ['', '', '300px']
    }
  },

  // ? =========================
  // ? ======  reuseables  ======
  // ? =========================

  title: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    fontSize: ['2rem', '2.5rem', '3rem', '4rem'],
    color: 'primary',
    fontWeight: '500',
    whiteSpace: 'pre-wrap',
    order: '1',
    '::after': {
      content: "'✻'",
      fontSize: '1rem',
      margin: '1rem 0rem'
    }
  },
  subtitle: {
    color: 'text',
    fontWeight: 'bold',
    textTransform: 'initial',
    fontFamily: 'body',
    fontSize: ['1.25rem', '1.5rem', '1.75rem', '2rem'],
    textTransform: 'uppercase',
    order: '2',
    borderTop: 'solid 1px',
    borderBottom: 'solid 1px',
    marginBottom: '2rem',
    color: 'inherit'
  },
  text: {
    lineHeight: '1.75',
    marginBottom: '1rem',
    order: '3',
    p: {
      color: 'inherit',
      textAlign: 'center'
    }
  },

  sideBySide1: {
    padding: '0rem',
    height: ['', '', '80vh', '80vh'],
    backgroundPosition: ['center 100%', '', 'center center'],
    backgroundSize: ['150%', '', 'cover'],
    '.section': {
      width: ['', '', '40%'],
      maxWidth: 'unset',
      margin: ' 0rem auto 0rem 0rem',
      backgroundColor: 'white',
      padding: ['2rem 1rem', '', '3rem'],
      marginBottom: ['100%', '', '0rem']
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    '.section': {
      width: ['', '', '40%'],
      maxWidth: 'unset',
      margin: ' 0rem 0rem 0rem auto',
      backgroundColor: 'black',
      padding: ['1rem', '', '3rem'],
      color: 'white',
      padding: ['2rem 1rem', '', '3rem'],
      marginBottom: ['100%', '', '0rem']
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  // ? =========================
  // ? ======  Home page  ======
  // ? =========================

  homepageHero: {
    height: ['60vh', '', '', '60vh'],
    // marginBottom: ['2rem', '', '2rem', '2rem'],
    '::after': {
      content: "''",
      width: '100%',
      height: '100%',
      position: 'absolute',
      left: '0rem',
      top: '0rem',
      background: 'linear-gradient(193deg, rgba(46, 59, 73, 0) 0%, rgba(49, 55, 62, 0) 64%, rgb(0, 0, 0) 100%)'
    },
    '.slick-slider': {
      height: ['60vh', '', '', '60vh'],
      '.slick-slide > div': {
        height: ['60vh', '', '', '60vh']
      },
      '.slick-prev, .slick-next': {
        color: 'white',
        bottom: '0rem',
        backgroundColor: 'primary',
        height: '30px',
        borderRadius: '100px',
        padding: '5px'
      },
      '.slick-slide img': {
        height: ['60vh', '', '', '60vh']
      }
    },
    '.hero_content_container': {
      // padding: ['2rem', '', '', '4.5rem', '5.5rem'],
      textShadow: '3px 1px 1px #434343',
      width: ['75%', '50%', '', '55%', '50%', '45%'],
      // marginLeft: ['', '', '', '4rem'],
      top: 'unset',
      bottom: '0rem',
      transform: 'unset',
      // display: 'none',
      '.title': {
        variant: 'customVariants.title',
        '::after': {}, // removes the star
        order: '2'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        order: '1',
        color: 'white',
        width: 'fit-content'
      },

      '.text': {
        variant: 'customVariants.text'
      }
    }
  },
  homepageHeroShout: {
    display: 'none'
  },

  homepageShout: {
    flexDirection: ['column', 'column', 'row'],
    '.imageContainer': {
      width: ['100%', '100%', '40%'],
      maxHeight: ['', '', '55vh'],
      order: ['3', '', 'unset']
    },
    '.image': {
      padding: ['0rem', '', '1rem', '2rem'],
      objectFit: 'cover'
    },
    '.content': {
      backgroundColor: ['', '', 'black'],
      color: 'text',
      margin: ['', '', '2rem 1rem'],
      width: ['100%', '100%', '60%']
    },
    '.text': {
      lineHeight: '1.5',
      color: ['text', '', 'light'],
      fontSize: ['1.25rem', '1.5rem', '1.75rem', '2rem'],
      letterSpacing: '1px',
      fontFamily: 'heading'
    },
    '.title': {
      width: ['3rem', '', '', '4rem'],
      textTransform: 'uppercase',
      color: 'light',
      letterSpacing: '3px'
    },
    '.date': {
      color: 'light',
      display: 'none'
    },

    '.shoutCTA': {
      variant: 'buttons.primary'
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },
  homepageMenu: {
    variant: 'customVariants.sideBySide2'
  },
  homepageOrder: {
    variant: 'customVariants.sideBySide1'
  },
  homepageGallery: {
    variant: 'customVariants.sideBySide2'
  },
  homepageEvents: {
    variant: 'customVariants.sideBySide1'
  },

  instagramWidget: {
    display: 'flex',
    flexDirection: ['column', '', 'column'],
    justifyContent: 'center',
    alignItems: 'center',
    '.logoAndTextContainer': {
      display: 'none'
    },
    '.iFrameContainer': {
      width: ['100%', '100%', '100%']
    }
  },

  // ? =========================
  // ? =====  About page  ======
  // ? =========================

  aboutSection: {
    backgroundColor: 'transparent',
    '.title': {
      variant: 'customVariants.title',
      order: 'unset'
    }
  },

  // ? =========================
  // ? ====  Meal prep page  ===
  // ? =========================

  menu: {
    '.menuSectionTitle': {
      margin: '0rem 0rem 2rem',
      textTransform: 'uppercase',
      fontSize: ['1.5rem', '', '2rem'],
      flexDirection: 'row',
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'dark',
      '::before': {
        content: "''",
        width: '100px',
        height: '3px',
        backgroundColor: 'dark',
        margin: '0rem 1rem'
      },
      '::after': {
        content: "''",
        width: '100px',
        height: '3px',
        backgroundColor: 'dark',
        margin: '0rem 1rem'
      }
    },
    '.menuSectionDescription': {
      fontSize: '1rem',
      maxWidth: '767px',
      margin: '1rem auto',
      lineHeight: '1.7'
    },
    '.allInContainerWrapper': {},
    '.menuItemInnerContainer': {},
    '.menuItemImageContainer': {},
    '.menuItemName': {
      fontSize: '1.1rem',
      marginBottom: '0rem',
      textAlign: 'left',
      textTransform: 'uppercase'
    },
    '.menuPriceContainer': {
      borderBottom: 'dotted 2px',
      borderColor: 'dark'
    },
    '.menuItemContentContainer': {
      margin: '0rem'
    },

    '.dropdownContainer': {
      display: 'none'
    },
    '.section-cell': {},
    '.cellImageContainer': {},
    '.menuCell, .cellImage': {},
    '.menuCell': {},
    '.cellName': {},
    '.menuItemContainerImgActive': {
      width: ['100%', '100%', '100%', '100%']
    },
    '.backToMenuBtn': {
      variant: 'buttons.primary',
      marginBottom: '3rem'
    },
    '.menuItemPrice': {
      fontSize: '1.25rem',
      fontWeight: 'bold'
    },
    '.tabsRow': {
      marginBottom: '1rem'
    }
  },

  // ? =========================
  // ? ====  Catering page  ====
  // ? =========================

  cateringMenu: {
    variant: 'customVariants.menu'
  },

  // ? =========================
  // ? ====  Gallery page  =====
  // ? =========================

  gallery: {
    padding: '1rem',
    minHeight: '60vh',
    '.albumsContainer': {},
    '.albumsCTA': {
      height: ['350px', '', '500px'],
      alignItems: 'flex-end',
      flexGrow: '1',
      transition: ' all ease-in-out 0.7s',
      ':hover': {
        backgroundPosition: 'bottom right'
      }
    },
    '.albumTitle': {
      variant: 'buttons.secondary',
      color: 'white',
      borderColor: 'white',
      fontSize: '2rem',
      marginBottom: '1rem'
    }
  },

  // ? =========================
  // ? ====  events page  =====
  // ? =========================

  events: {
    '.eventItemImage': {
      minHeight: 'unset'
    },
    '.eventDays, .eventDate': {
      color: 'black'
    },
    '.modal, .modalContentContainer ': {
      backgroundColor: 'white'
    }
  },

  // ? =========================
  // ? ====  Contact Page  =====
  // ? =========================

  locationMap: {
    order: '3',
    h3: {
      textTransform: 'uppercase'
    },

    '.dayofWeekText': {
      margin: '0 auto 0 0'
    },
    '.content_container ': {
      backgroundColor: 'primary'
    },
    '.title': {
      variant: 'customVariants.title',
      color: 'black',
      order: 'unset'
    }
  },

  contactForm: {
    padding: '4rem 1rem',
    '.title': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: ['2rem', '2.5rem', '', '3rem'],
      color: 'primary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      // borderBottom: '2px solid',
      // borderColor: 'secondary',
      marginBottom: '2rem'
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1rem',
      opacity: '0.8',
      marginBottom: '4rem'
    },
    form: {
      display: 'none',
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },
      '.submitBtn': {
        variant: 'buttons.primary'
      }
    }
  }
}
