export default {
  primary: {
    color: 'dark',
    border: 'none',
    borderTop: 'solid 1px',
    borderBottom: 'solid 1px',
    fontSize: '0.85rem',
    fontWeight: '700',
    textTransform: 'uppercase',
    paddingBottom: '0rem',
    padding: '0.5rem 3rem',
    borderColor: '#383838',
    backgroundColor: 'transparent',
    ':hover': {
      backgroundColor: 'dark',
      color: 'primary'
    }
  },
  secondary: {
    backgrounColor: 'transparent',
    color: 'white',
    border: 'none',
    borderTop: 'solid 1px',
    borderBottom: 'solid 1px',
    borderColor: 'white',
    fontSize: '0.85rem',
    fontWeight: '700',
    textTransform: 'uppercase',
    paddingBottom: '0rem',
    padding: '0.5rem 3rem',
    backgroundColor: 'transparent',
    ':hover': {
      backgroundColor: 'white',
      color: 'primary'
    }
  }
}
